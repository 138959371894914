<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      title: "Profile",
      items: [
        {
          text: "Velzon",
          href: "/",
        },
        {
          text: "Profile",
          active: true,
        },
      ],

      userSlug: '',
      userInfo: {},
      roles: []
    };
  },
  computed: {
    user: {
      get() {
        return this.userInfo || {}
      }
    }
  },
  methods: {
    async getUser() {
      try {
        let response = await axios.get(`/user/${this.userSlug}/`)
        this.userInfo = response.data
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'User not found!',
          showConfirmButton: false,
        }).then(() => {
          this.$router.push({name: 'users_list'})
        })
      }
    },
    rolesLoaded(roles) {
      this.roles = roles
    }
  },
  mounted() {
    this.userSlug = this.$route.params.slug
  },
};
</script>

<template>
  User settings
</template>